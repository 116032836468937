import { navigate } from "@reach/router";
import { AddToCartButton } from "components/AddToCartButton";
import ButtonLink from "components/ButtonLink";
import ExpandablePanel from "components/ExpandablePanel";
import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import ScalapayLogo from "components/icons/Scalapay";
import ShopifyImage from "components/ShopifyImage";
import {
  DatoCmsProduct,
  ShopifyProduct,
  ShopifyProductVariant,
} from "graphql-typings";
import { useCart } from "hooks/useCart";
import { useLocale } from "hooks/useLocale";
import { useProductAvailability } from "hooks/useProductAvailability";
import useProductOptions from "hooks/useProductOptions";
import { useTranslation } from "hooks/useTranslation";
import React, { useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "react-carousel";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import formatMoney from "utils/common/formatMoney";
import {
  computePathFromLocalizedData,
  getAllProductPathLocales,
} from "utils/common/navigation";
import HelpCircle from "./icons/HelpCircle";
import Link from "./Link";
import ScalapayModal from "./ScalapayModal";
import SizeGuideModal from "./SizeGuideModal";
import ColorSelector from "./variant-selector/ColorSelector";
import CustomTextSelector from "./variant-selector/CustomTextSelector";
import EngravingSelector from "./variant-selector/EngravingSelector";
import ListSelector from "./variant-selector/ListSelector";

interface Props {
  datocmsProduct: DatoCmsProduct;
  shopifyProduct: ShopifyProduct;
  otherSubjects: any[];
  general: any;
  selectedVariant: ShopifyProductVariant;
  selectedOptions: { [key: string]: string };
  customAttributes: { key: string; value: string }[];
  extractOptionNamesOrdered: (_variants: ShopifyProductVariant[]) => string[];
  extractLocalizedOptions: (
    _optionName: string
  ) => { name: string; color?: string; displayValue: string }[];
  reconcileVariantSelector: (_option: { [key: string]: string }) => void;
  reconcileCustomText: (_text: string, _index: number) => void;
  reconcileEngraving: (enabled: boolean, text?: string, font?: string) => void;
  productCollections?: { handle: string; title: string }[];
}

/**
 * Product info panel
 */
function Info({
  datocmsProduct,
  shopifyProduct,
  otherSubjects,
  general,
  selectedVariant,
  selectedOptions,
  customAttributes,
  extractOptionNamesOrdered,
  extractLocalizedOptions,
  reconcileVariantSelector,
  reconcileCustomText,
  reconcileEngraving,
  productCollections,
}: Props) {
  const { locale, defaultLocale } = useLocale();
  const { cart, addToCart } = useCart();
  const { formatMessage } = useTranslation();
  const productOptions = useProductOptions();
  const availability = useProductAvailability(shopifyProduct);
  const [isOpenSizeGuide, setOpenSizeGuide] = useState(false);
  const [isOpenScalapayInfo, setOpenScalapayInfo] = useState(false);

  if (!datocmsProduct.category) {
    throw new Error(`Product ${datocmsProduct.handle} has no category!`);
  }
  const paths = getAllProductPathLocales(datocmsProduct, defaultLocale);
  const isDiscounted =
    selectedVariant.compareAtPrice &&
    selectedVariant.compareAtPrice !== selectedVariant.price;

  const isAddingToCart =
    cart && cart.lineItems.some((lineItem) => lineItem.state === "adding");

  //FIXME: this is a temporary fix
  // const localizedShippingAndReturn = general._allAdditionalProductDetailsLocales.find(
  //   (detailsLocale: FieldLocale<any>) => detailsLocale.locale === locale
  // );
  let localizedShippingAndReturn = {};
  localizedShippingAndReturn["valueNode"] = {};
  localizedShippingAndReturn["valueNode"]["childMarkdownRemark"] = {};
  localizedShippingAndReturn["valueNode"]["childMarkdownRemark"]["html"] =
    general.additionalProductDetailsNode.childMarkdownRemark!.html;

  const handleAddToCart = () => {
    addToCart({
      variant: {
        id: selectedVariant.storefrontId!,
        price: selectedVariant.price!,
        compareAtPrice: selectedVariant.compareAtPrice!,
        image: selectedVariant.image
          ? {
              altText: datocmsProduct.title || "",
              originalSrc: selectedVariant.image.originalSrc as string,
            }
          : undefined,
      },
      quantity: 1,
      customAttributes,
      allLocaleValues: {
        titles: datocmsProduct._allTitleLocales as any,
        paths,
      },
    });

    const productId = selectedVariant.product.shopifyId?.replace(
      "gid://shopify/Product/",
      ""
    );
    const variantId = selectedVariant.shopifyId?.replace(
      "gid://shopify/ProductVariant/",
      ""
    );

    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "addToCart",
      eventCategory: "Ecommerce",
      eventAction: "Add to Cart",
      ecommerce: {
        currencyCode: "EUR",
        add: {
          products: [
            {
              id: selectedVariant.sku,
              name:
                shopifyProduct.variants!.length > 1
                  ? `${shopifyProduct.title} - ${selectedVariant.title}`
                  : shopifyProduct.title,
              variant: selectedVariant.title,
              price: selectedVariant.price!,
              quantity: 1,
              product_id: productId,
              variant_id: variantId,
            },
          ],
        },
      },
    });
  };

  /**
   * Render a variant selector
   */
  const renderVariantSelector = (optionName: string, index: number) => {
    if (optionName === "Numero di lettere") {
      return null;
    }
    const option = productOptions[optionName];
    const title = (option && option.optionName) || optionName;
    const value = selectedVariant && selectedOptions[optionName];
    const options = extractLocalizedOptions(optionName);

    switch (option && option.selectorType) {
      case "colore":
        return (
          <ColorSelector
            key={index}
            title={title}
            shopifyOptionName={optionName}
            value={value}
            options={options}
            onChange={reconcileVariantSelector}
          />
        );
      case "lista":
        return (
          <ListSelector
            key={index}
            title={title}
            shopifyOptionName={optionName}
            value={value}
            options={options}
            onChange={reconcileVariantSelector}
          />
        );
    }
  };

  /**
   * Renders custom fields if needed
   */
  const renderCustomTextSelectors = () => {
    const selectors = [];
    if (datocmsProduct.productCustomisation) {
      const numberOfFields =
        datocmsProduct.productCustomisationNumberOfFields || 1;
      const customisation = {
        case: datocmsProduct.productCustomisationCase,
        symbols: datocmsProduct.productCustomisationSymbols,
        numberOfFields: datocmsProduct.productCustomisationNumberOfFields,
        charactersLimit: datocmsProduct.productCustomisationCharactersLimit,
        progressivePrice: datocmsProduct.productCustomisationProgressivePrice,
      };
      for (let i = 0; i < numberOfFields; i++) {
        selectors.push(
          <FormattedMessage id="label.text" key={i}>
            {(titlePrefix) => (
              <CustomTextSelector
                title={`${titlePrefix}${i != 0 ? ` ${i + 1}` : ``}:`}
                customisation={customisation}
                onChange={(text) => reconcileCustomText(text, i)}
              />
            )}
          </FormattedMessage>
        );
      }
    }
    return selectors;
  };

  /**
   * Renders customisazion variant name if needed
   */
  const renderCustomisationVariantName = () => {
    if (datocmsProduct.productCustomisation) {
      if (
        selectedVariant &&
        selectedOptions["Numero di lettere"] &&
        customAttributes &&
        !customAttributes.find((elem: any) => elem.value === "")
      ) {
        const options = extractLocalizedOptions("Numero di lettere");
        return (
          <NumberOfLetters>
            <FormattedMessage id="label.numberOfLetters" />:{" "}
            <NumberOfLettersValue>
              {(options &&
                options.find(
                  (opt: {
                    name: string;
                    color?: string;
                    displayValue: string;
                  }) => opt.name === selectedOptions["Numero di lettere"]
                )!.displayValue) ||
                selectedOptions["Numero di lettere"]}
            </NumberOfLettersValue>
          </NumberOfLetters>
        );
      }
      return null;
    }
  };

  /**
   * Renders engraving fields if needed
   */
  const renderEngravingSelectors = () => {
    if (datocmsProduct.productEngraving) {
      const customisation = {
        case: datocmsProduct.productEngravingCase,
        symbols: datocmsProduct.productEngravingSymbols,
        charactersLimit: datocmsProduct.productEngravingCharactersLimit,
        fonts: datocmsProduct.productEngravingFont,
      };

      return (
        <EngravingSelector
          customisation={customisation}
          onChange={(enabled: boolean, text: string, font: string) =>
            reconcileEngraving(enabled, text, font)
          }
        />
      );
    }
  };

  return (
    <ProductInfoContainer>
      <Heading>
        <Title>{datocmsProduct.title}</Title>
        {productCollections && (
          <CollectionsBadges>
            {productCollections
              .filter((collection) => collection.handle !== "all")
              .map((collection, index) => (
                <CollectionBadge key={index}>
                  <Link
                    to={computePathFromLocalizedData(
                      locale,
                      defaultLocale,
                      "DatoCmsCollection",
                      collection.handle
                    )}
                  >
                    {collection.title}
                  </Link>
                </CollectionBadge>
              ))}
          </CollectionsBadges>
        )}
      </Heading>
      {!datocmsProduct.notForSale && (
        <>
          <PriceContainer>
            {selectedVariant && (
              <Price>
                {isDiscounted && (
                  <CompareAtPrice>
                    {formatMoney(
                      Number(selectedVariant.compareAtPrice) * 100,
                      "€{{amount}}"
                    )}
                  </CompareAtPrice>
                )}
                <OriginalPrice isDiscounted={Boolean(isDiscounted)}>
                  {formatMoney(
                    Number(selectedVariant.price) * 100,
                    "€{{amount}}"
                  )}
                </OriginalPrice>
              </Price>
            )}
            <Scalapay>
              <ScalapayText>
                <FormattedMessage
                  id="scalapay.cta"
                  values={{
                    price: (
                      <ScalapayPrice>
                        {formatMoney(
                          (Number(selectedVariant.price) / 3) * 100,
                          "€{{amount}}"
                        )}
                      </ScalapayPrice>
                    ),
                  }}
                />
              </ScalapayText>
              <ScalapayInfo onClick={() => setOpenScalapayInfo(true)}>
                <ScalapayLogoContainer>
                  <ScalapayLogo />
                </ScalapayLogoContainer>
                ⓘ
              </ScalapayInfo>
              <ScalapayModal
                isOpen={isOpenScalapayInfo}
                onClose={() => setOpenScalapayInfo(false)}
              ></ScalapayModal>
            </Scalapay>
          </PriceContainer>
          <VariantList>
            {extractOptionNamesOrdered(
              shopifyProduct.variants as ShopifyProductVariant[]
            ).map((variantString: string, index: number) =>
              renderVariantSelector(variantString, index)
            )}
            {renderCustomTextSelectors()}
            {renderCustomisationVariantName()}
            {renderEngravingSelectors()}
          </VariantList>
          {datocmsProduct.note && (
            <Note
              dangerouslySetInnerHTML={{
                __html: datocmsProduct.noteTextNode!.childMarkdownRemark!
                  .html as string,
              }}
            />
          )}
          {datocmsProduct.sizeGuide && (
            <>
              <SizeGuideTrigger onClick={() => setOpenSizeGuide(true)}>
                <HelpCircle />
                <SizeGuideLabel>
                  {formatMessage({ id: "sizeGuide.trigger" })}
                </SizeGuideLabel>
              </SizeGuideTrigger>
              <SizeGuideModal
                isOpen={isOpenSizeGuide}
                data={datocmsProduct.sizeGuide}
                onClose={() => setOpenSizeGuide(false)}
              />
            </>
          )}
          <AddToCart
            isLoading={isAddingToCart || !availability}
            onClick={handleAddToCart}
            disabled={
              !selectedVariant ||
              !availability ||
              !availability[selectedVariant.storefrontId as string] ||
              (customAttributes &&
                customAttributes.length > 0 &&
                customAttributes.find((elem) => elem.value === "")) ||
              datocmsProduct.hidden
            }
          >
            {availability &&
            availability[selectedVariant.storefrontId as string] &&
            !datocmsProduct.hidden ? (
              <FormattedMessage id="button.addToCart" />
            ) : (
              <FormattedMessage id="button.soldOut" />
            )}
          </AddToCart>
        </>
      )}
      {datocmsProduct.additionalCta && (
        <AdditionalCta
          size="large"
          to={
            datocmsProduct.additionalCtaLink
              ? computePathFromLocalizedData(
                  locale,
                  defaultLocale,
                  datocmsProduct.additionalCtaLink.__typename,
                  datocmsProduct.additionalCtaLink.handle,
                  datocmsProduct.additionalCtaLink.category &&
                    datocmsProduct.additionalCtaLink.category.handle
                )
              : datocmsProduct.additionalCtaCustomLink
          }
        >
          {datocmsProduct.additionalCtaTitle}
        </AdditionalCta>
      )}
      <MoreInfo>
        <ExpandablePanel
          title={formatMessage({ id: "product.details" })}
          content={
            (datocmsProduct.descriptionNode &&
              datocmsProduct.descriptionNode.childMarkdownRemark!.html) ||
            ""
          }
          isHtml
          isOpen={true}
        />
        <ExpandablePanel
          title={formatMessage({ id: "product.shipping" })}
          content={
            (localizedShippingAndReturn.valueNode &&
              localizedShippingAndReturn.valueNode.childMarkdownRemark!.html) ||
            ""
          }
          isHtml
        />
      </MoreInfo>
      {!!otherSubjects.length && (
        <OtherSubjects>
          <OtherSubjectsTitle>
            <FormattedMessage id="label.otherSubjects" />
          </OtherSubjectsTitle>

          <OtherSubjectsContainer>
            <CarouselProvider
              totalSlides={otherSubjects.length}
              visibleSlides={3}
            >
              <Slider>
                {otherSubjects.map((otherSubject: any, index: number) => (
                  <Slide
                    key={index}
                    index={index}
                    onClick={() => navigate(otherSubject.url)}
                  >
                    <ThumbnailContainer>
                      <ShopifyImageThumbnail
                        src={otherSubject.images[0].originalSrc}
                        height={300}
                        width={300}
                      />
                    </ThumbnailContainer>
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
            </CarouselProvider>
          </OtherSubjectsContainer>
        </OtherSubjects>
      )}
    </ProductInfoContainer>
  );
}

const Heading = styled.div`
  margin-top: 40px;
  margin-bottom: 25px;
  @media (max-width: 500px) {
    margin-top: 25px;
    margin-bottom: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.1em;
  color: #333;
  padding: 0;
  margin: 0;

  @media (max-width: 1240px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const CollectionsBadges = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
`;
const CollectionBadge = styled.div`
  a {
    color: ${({ theme }) => theme.colors.main};
    padding: 0px 8px 1px 8px;
    display: block;
    font-size: 13px;
    background-color: ${({ theme }) => theme.colors.background2};
    border-radius: 100px;
    margin-right: 4px;
    border: 1px solid ${({ theme }) => theme.colors.brand100};
    transition: 0.3s all;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brand100};
      background-color: ${({ theme }) => theme.colors.brand100};
    }
  }
`;

const NumberOfLetters = styled.div`
  font-size: 14px;
  letter-spacing: 0.08em;
  margin-bottom: 20px;
`;

const NumberOfLettersValue = styled.span`
  font-weight: 600;
`;

const PriceContainer = styled.div`
  margin-bottom: 30px;
`;
const Price = styled.div`
  display: flex;
  align-items: center;
`;
const CompareAtPrice = styled.span`
  font-size: 16px;
  letter-spacing: 0.1em;
  padding-right: 10px;
  text-decoration: line-through;
  color: #333;
`;

const OriginalPrice = styled.span<{ isDiscounted?: boolean }>`
  font-size: 22px;
  letter-spacing: 0.1em;
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
`;

const Scalapay = styled.div`
  align-items: center;
  font-size: 14px;
  margin-top: 6px;
  align-items: center;
  display: flex;
`;

const ScalapayText = styled.span`
  white-space: pre;
  display: inline-block;
`;

const ScalapayPrice = styled.span`
  font-weight: 700;
`;

const ScalapayLogoContainer = styled.div`
  height: 16px;
  width: 80px;
  display: flex;
  padding-right: 4px;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const ScalapayInfo = styled.span`
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding-left: 4px;
`;

const MoreInfo = styled.div`
  padding-top: 20px;
`;

const Note = styled.div`
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 1.4em;
  font-weight: 600;
`;

const SizeGuideLabel = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
`;

const SizeGuideTrigger = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  transition: 0.3s all;
  svg {
    height: 18px;
    width: 18px;
    display: block;
    stroke: ${({ theme }) => theme.colors.main};
    margin-top: 2px;
  }
  &:hover {
    svg {
      stroke: #806724;
    }
    ${SizeGuideLabel} {
      color: #806724;
    }
  }
`;

const AddToCart = styled(AddToCartButton)``;

const AdditionalCta = styled(ButtonLink)`
  margin-top: 10px;
  background-color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.main};
  &:hover {
    color: #fff;
    background-color: #806724;
    border: 2px solid #806724;
  }
`;

const OtherSubjects = styled.div``;

const OtherSubjectsContainer = styled.div`
  position: relative;
  margin: 0 30px;
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const OtherSubjectsTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  text-align: center;
  color: ${({ theme }) => theme.colors.main};
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-top: 40px;
`;
const ProductInfoContainer = styled.div`
  width: 100%;
`;

const VariantList = styled.div``;

const ThumbnailContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  transition: 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    margin: -10px;
  }
  @media (max-width: 500px) {
    padding: 5px;
    &:hover {
      margin: 0;
    }
  }
`;
const ShopifyImageThumbnail = styled(ShopifyImage)`
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  border: 5px solid ${({ theme }) => theme.colors.background2};
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    border: 5px solid ${({ theme }) => theme.colors.main};
  }
`;

export default Info;
