import InputText from "components/InputText";
import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

interface Props {
  title: string;
  customisation: any;
  onChange: (option: any) => void;
}

/**
 * Generic selector for color-like variants
 */
const CustomTextSelector: React.SFC<Props> = ({
  title,
  customisation,
  onChange,
}) => {
  // State variables
  const [text, setText] = useState("");

  // Refs
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPosition = useRef(0);

  useEffect(() => {
    inputRef.current!.setSelectionRange(
      cursorPosition.current,
      cursorPosition.current
    );
  }, [text]);

  /**
   * Handle color selection
   */
  const processText = (newText: string) => {
    let validatedText = newText || "";
    let previousLength = text.length || 0;

    if (customisation.charactersLimit) {
      const variantSelectorNumber = (
        validatedText.match(new RegExp("\uFE0E", "gi")) || []
      ).length;
      if (
        validatedText.length - variantSelectorNumber >
        customisation.charactersLimit
      ) {
        validatedText = validatedText.substr(0, previousLength);
      }
    }
    if (customisation.case) {
      switch (customisation.case) {
        case "UPPERCASE":
          validatedText = validatedText.toUpperCase();
          break;
        case "lowercase":
          validatedText = validatedText.toLowerCase();
          break;
        case "Capitalize":
          validatedText = capitalize(validatedText);
      }
    }
    if (customisation.symbols) {
      let rawRegex = "[^";
      if (customisation.symbols.includes("lettere")) {
        rawRegex = rawRegex.concat("a-zA-Z");
      }
      if (customisation.symbols.includes("numeri")) {
        rawRegex = rawRegex.concat("0-9");
      }
      const otherChars = customisation.symbols.replace(
        new RegExp("(numeri|lettere|,)", "gi"),
        ""
      );
      rawRegex = rawRegex.concat(otherChars);
      rawRegex = rawRegex.concat("\uFE0E");
      rawRegex = rawRegex.concat("]+");
      const regex = new RegExp(rawRegex, "gi");
      validatedText = validatedText.replace(regex, "");
    }

    if (text != validatedText) {
      setText(validatedText);
      onChange(validatedText);
    }
  };

  const handleChange = (text: string) => {
    cursorPosition.current = inputRef.current!.selectionStart || 0;
    processText(text);
  };

  const handleButtonPress = (symbol: string) => {
    const startPosition = inputRef.current!.selectionStart || 0;
    const endPosition = inputRef.current!.selectionEnd || 0;
    cursorPosition.current = startPosition + symbol.length;
    const newText = `${text.substr(0, startPosition)}${symbol}${text.substr(
      endPosition
    )}`;
    processText(newText);
  };

  const capitalize = (text: string) => {
    if (text && text.length === 1) {
      return text.charAt(0).toUpperCase();
    }
    if (text && text.length > 1) {
      return `${text.charAt(0).toUpperCase()}${text
        .substr(1, text.length)
        .toLowerCase()}`;
    }
    return text;
  };

  return (
    <Container>
      <Title>{title}</Title>
      <FormattedMessage id="label.customText">
        {(placeholder) => (
          <InputText
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={text || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value)
            }
          />
        )}
      </FormattedMessage>
      {customisation.symbols.replace(
        new RegExp("(numeri|lettere|,|\\s)", "gi"),
        ""
      ) && (
        <Symbols>
          {customisation.symbols
            .replace(new RegExp("(numeri|lettere|,|\\s)", "gi"), "")
            .split("")
            .map((char: string, index: number) => (
              <Symbol
                key={index}
                onClick={() =>
                  handleButtonPress(
                    `${
                      char.length === 1
                        ? char
                        : String.fromCharCode(char.charCodeAt(0))
                    }\uFE0E`
                  )
                }
              >
                {`${
                  char.length === 1
                    ? char
                    : String.fromCharCode(char.charCodeAt(0))
                }\uFE0E`}
              </Symbol>
            ))}
        </Symbols>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 20px;
  ${InputText} {
    margin-bottom: 10px;
    font-weight: 600;
    &::placeholder {
      letter-spacing: 0.08em;
      font-weight: 400;
    }
  }
`;

const Title = styled.div`
  font-size: 14px;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
`;

const Symbols = styled.div`
  width: calc(100% - 8px);
  margin-left: -4px;
  display: flex;
`;

const Symbol = styled.button`
  background-color: ${({ theme }) => theme.colors.main};
  color: white;
  outline: inherit;
  height: 32px;
  font-size: 14px;
  width: 32px;
  border: none;
  font-family: soleil, sans-serif;
  transition: 0.3s all;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  padding-bottom: 2px;

  &:hover {
    cursor: pointer;
    background-color: #806724;
  }
`;

export default CustomTextSelector;
