import { ShopifyProduct, ShopifyProductVariant } from "graphql-typings";
import { useQuery } from "./useGraphQL";
import { useMemo, useEffect, useState } from "react";

interface IOption {
  [key: string]: { name: string; displayValue: string };
}

export type TCombination = { [key: string]: string };

export function useProductAvailability(shopifyProduct: ShopifyProduct) {
  const [availabilities, setAvailabilities] = useState<
    { [key: string]: boolean } | undefined
  >();
  const [, getAvailabilities] = useQuery<
    ProductByHandleResult,
    ProductByHandleVariables
  >(productByHandle, {
    autoExecution: false
  });

  useEffect(() => {
    const fetchAvailabilities = async () => {
      const result = await getAvailabilities({
        handle: shopifyProduct.handle || ""
      });
      setAvailabilities(
        result.data &&
          result.data.productByHandle.variants.edges.reduce(
            (acc, v) => ({
              ...acc,
              [v.node.id]: v.node.availableForSale
            }),
            {}
          )
      );
    };
    fetchAvailabilities();
  }, []);

  return availabilities;
}

export interface ProductByHandleResult {
  productByHandle: {
    variants: { edges: { node: { id: string; availableForSale: boolean } }[] };
  };
}

export interface ProductByHandleVariables {
  handle: string;
}

export const productByHandle = /* GraphQL */ `
  query productByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      variants(first: 250) {
        edges {
          node {
            id
            availableForSale
          }
        }
      }
    }
  }
`;
