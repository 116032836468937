import {
  DatoCmsProduct,
  ShopifyProduct,
  ShopifyProductVariant,
} from "graphql-typings";
import { isEqual } from "lodash";
import { useState } from "react";
import useProductOptions from "./useProductOptions";

export type TCombination = { [key: string]: string };

interface ProductOptionValue {
  shopifyValue: string;
  value: string;
  color: string;
}

export function useProductConfigurator(
  shopifyProduct: ShopifyProduct,
  datocmsProduct: DatoCmsProduct
) {
  const variants = shopifyProduct.variants as ShopifyProductVariant[];

  // Initializers
  const initSelectedVariant = () => {
    if (!variants || variants.length === 0) {
      throw new Error("Product has no variants");
    }
    return variants && variants[0];
  };

  const initSelectedOptions = () => {
    if (!variants || variants.length === 0) {
      throw new Error("Product has no variants");
    }
    return optionsToObj(variants[0]);
  };

  const initCustomAttributes = () => {
    let value;
    const customAttributesCount =
      (datocmsProduct.productCustomisation &&
        datocmsProduct.productCustomisationNumberOfFields) ||
      0;
    for (let i = 0; i < customAttributesCount; i++) {
      value = [...(value || []), { key: `text_${i + 1}`, value: "" }];
    }
    return value;
  };

  // State variables
  const [selectedVariant, setSelectedVariant] = useState(initSelectedVariant());
  const [selectedOptions, setSelectedOptions] = useState(initSelectedOptions());
  const [customAttributes, setCustomAttributes] = useState(
    initCustomAttributes()
  );

  const productOptions = useProductOptions();

  /**
   * Reconcile the selection coming from a variant selector
   */
  const reconcileVariantSelector = (option: any) => {
    const newOptions = { ...selectedOptions, ...option };
    setSelectedOptions(newOptions);
    const foundVariant = findFromOptions(newOptions, variants);
    setSelectedVariant(foundVariant as ShopifyProductVariant);
  };

  /**
   * React to change in custom fields
   */
  const reconcileCustomText = (text: string, index: number) => {
    // Update custom attributes
    setCustomAttributes((lp: any) => {
      const newLp = [...lp];
      newLp[index] = { key: `text_${index}`, value: text };
      return newLp;
    });
    // Check if need to change selected variant
    const optionNames = extractOptionNamesOrdered(variants);
    if (
      datocmsProduct.productCustomisationProgressivePrice &&
      optionNames.includes("Numero di lettere")
    ) {
      const options = extractOptionValues(variants)["Numero di lettere"];
      const optionsToUpperBounds = mapOptionsToUpperBounds(options);
      const sortedOptionsToUpperBounds = optionsToUpperBounds.sort(
        (a: any, b: any) => a.upperBound - b.upperBound
      );
      let option;
      for (let i = 0; i < options.length; i++) {
        if (
          sortedOptionsToUpperBounds[i].upperBound >=
          text.replace(new RegExp("\uFE0E", "gi"), "").length
        ) {
          option = sortedOptionsToUpperBounds[i].option;
          break;
        } else if (
          sortedOptionsToUpperBounds[i].upperBound <
          text.replace(new RegExp("\uFE0E", "gi"), "").length
        ) {
          option = sortedOptionsToUpperBounds[i].option;
        }
      }
      reconcileVariantSelector({
        ["Numero di lettere"]: option,
      });
    }
  };

  const reconcileEngraving = (
    enabled: boolean,
    text: string = "",
    font: string = ""
  ) => {
    // Update custom attributes
    setCustomAttributes((lp: { key: string; value: string }[] | undefined) => {
      let newLp = [...(lp || [])];
      const engravingText = newLp?.find(
        (prop) => prop.key === "engraving_text"
      );
      const engravingFont = newLp?.find(
        (prop) => prop.key === "engraving_font"
      );
      if (enabled) {
        if (engravingText && engravingFont) {
          engravingText.value = text;
          engravingFont.value = font;
        } else {
          newLp.push({ key: "engraving_text", value: text });
          newLp.push({ key: "engraving_font", value: font });
        }
      } else {
        newLp = newLp.filter(
          (lp) => lp.key !== "engraving_text" && lp.key !== "engraving_font"
        );
      }
      return newLp;
    });
    const optionNames = extractOptionNamesOrdered(variants);
    if (datocmsProduct.productEngraving && optionNames.includes("Incisione")) {
      reconcileVariantSelector({
        ["Incisione"]: enabled ? "Con incisione" : "Senza incisione",
      });
    }
  };

  /**
   * Produce an array of the available variant options,
   * ordered by priority
   */
  const extractOptionNamesOrdered = (variants: ShopifyProductVariant[]) =>
    variants.reduce((acc: string[], variant: any) => {
      variant.selectedOptions.forEach((option: any) => {
        const name = option.name;
        if (!acc.includes(name)) {
          acc.push(name);
        }
      });
      return acc;
    }, []);

  /**
   * Produce an array of localized product options corresponding to
   */
  const extractLocalizedOptions = (option: string) =>
    extractOptionValues(variants)[option].reduce(
      (acc: any[], elem: string) =>
        productOptions[option]
          ? acc.concat(
              preprocessVariantOption(elem, productOptions[option].values)
            )
          : acc,
      []
    );

  return {
    selectedVariant,
    selectedOptions,
    customAttributes,
    reconcileVariantSelector,
    reconcileCustomText,
    reconcileEngraving,
    extractOptionNamesOrdered,
    extractLocalizedOptions,
  };
}

/**
 * Extract all the possible values of the variants associated with a product
 */
const extractOptionValues = (variants: any[]) =>
  variants.reduce((acc: any, variant: any) => {
    variant.selectedOptions.forEach((option: any) => {
      const values = acc[option.name] || [];
      if (!values.includes(option.value)) {
        values.push(option.value);
      }
      acc = { ...acc, [option.name]: values };
    });
    return acc;
  }, {});

/**
 * Enrich shopify variant name with their display value and eventually color
 */
const preprocessVariantOption = (
  shopifyOptionValue: string,
  productOptionValues?: ProductOptionValue[]
) => {
  const optionValue = findOptionValue(shopifyOptionValue, productOptionValues);
  return {
    name: shopifyOptionValue,
    displayValue: (optionValue && optionValue.value) || shopifyOptionValue,
  };
};

/**
 * Find the localized variant option for each shopify option value
 */
const findOptionValue = (
  shopifyOptionValue: string,
  productOptionValues?: ProductOptionValue[]
) => {
  return (
    productOptionValues &&
    productOptionValues.find(
      (option) => option!.shopifyValue === shopifyOptionValue
    )
  );
};

/**
 * Find a variant in a variant list starting from an options object
 */
const findFromOptions = (
  options: TCombination,
  variants: ShopifyProductVariant[]
) => variants.find((variant) => isEqual(options, optionsToObj(variant)));

/**
 * Transform variant options array to object
 */
const optionsToObj = (variant: ShopifyProductVariant) =>
  variant.selectedOptions!.reduce(
    (acc, option: any) => ({ ...acc, [option.name]: option.value }),
    {} as TCombination
  );

/**
 * Utility method to map the array of textual custom option variant names
 * to their letter number upper bound
 */
const mapOptionsToUpperBounds = (options: string[]) =>
  options.reduce((acc: any, option: string) => {
    const matches = option.match(new RegExp("[0-9]+", "gi"));
    const intMatches = matches!.map((match) => Number(match));
    const upperBound = intMatches!.reduce((a, b) => Math.max(a, b));
    return acc.concat({ option, upperBound });
  }, []);
