import React, { useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  title: string;
  content: string;
  isHtml?: boolean;
  isOpen?: boolean;
}

const ExpandablePanel: React.SFC<Props> = ({
  title,
  content,
  isHtml,
  isOpen,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpen || false);
  return (
    <ExpandablePanelContainer isOpen={isOpen}>
      <Header onClick={() => setIsExpanded(!isExpanded)}>
        <Title>{title}</Title>
        <PlusIcon isExpanded={isExpanded} />
      </Header>
      {isHtml ? (
        <Content
          isExpanded={isExpanded}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <Content isExpanded={isExpanded}>{content}</Content>
      )}
    </ExpandablePanelContainer>
  );
};

const ExpandablePanelContainer = styled.div`
  border-bottom: 1px solid #d0d0d0;
`;

const Header = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #333;
`;

const PlusIcon = styled.div<{ isExpanded: boolean }>`
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: #999;
    transition: 150ms all ease-out;
  }
  &:before {
    top: 6px;
    left: 2px;
    right: 2px;
    height: 2px;
  }
  &:after {
    top: 2px;
    left: 6px;
    bottom: 2px;
    width: 2px;
  }
  &:hover {
    cursor: pointer;
  }
  ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded &&
    css`
      &:after {
        transform: rotate(90deg);
      }
    `}
`;

const Content = styled.div<{ isExpanded: boolean }>`
  max-height: 0px;
  transition: 1s all;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 1.4em;
  color: #333;
  white-space: pre-line;
  &::after {
    content: "";
    display: inline-block;
    height: 20px;
    width: 100%;
  }
  ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded &&
    css`
      max-height: 800px;
    `}
`;

export default ExpandablePanel;
