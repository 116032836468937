import React, { useState } from "react";
import styled from "styled-components";
import Select from "components/Select";

interface Props {
  title: string;
  shopifyOptionName: string;
  value: string;
  options: any[];
  onChange: (option: any) => void;
}

/**
 * Generic selector for list-like variants
 */
const ListSelector: React.SFC<Props> = ({
  title,
  shopifyOptionName,
  value,
  options,
  onChange
}) => {
  const [selectedOption, setSelectedOption] = useState(value);

  /**
   * Handle list option selection
   */
  const handleChange = (option: any) => {
    setSelectedOption(option.name);
    onChange({ [shopifyOptionName]: option.name });
  };

  return (
    <Container>
      <Title>{title}:</Title>
      <Select
        placeholder={title}
        value={selectedOption}
        options={options}
        useSystemSelect={true}
        display={value => value.displayValue}
        compare={(value, option) => value === option.name}
        valueChange={handleChange}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 14px;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
`;

export default ListSelector;
