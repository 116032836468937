import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { DatoCmsProduct, ShopifyProductVariant } from "graphql-typings";
import { useFirstLoad } from "hooks/useFirstLoad";
import { FieldLocale, getLocalizedValue, useLocale } from "hooks/useLocale";
import React, { useEffect, useRef, useState } from "react";
import {
	ButtonBack,
	ButtonNext,
	CarouselProvider,
	Slide,
	Slider,
} from "react-carousel";
import styled from "styled-components";
import {
	getFirstMediaImage,
	mapMediaToImages,
	mapMediaToVideos,
} from "utils/image";
import Play from "./icons/Play";

interface Props {
	datocmsProduct: DatoCmsProduct;
	images: ReturnType<typeof mapMediaToImages>;
	videos?: ReturnType<typeof mapMediaToVideos>;
	selectedVariant?: ShopifyProductVariant;
}

interface StaticQuery {
	datoCmsGeneral: {
		_allDiscountedProductTextLocales: FieldLocale<string>[];
	};
}

/**
 * Gallery components
 * Show two coordinated image slider
 */
const Gallery: React.SFC<Props> = ({
	images,
	videos,
	selectedVariant,
	datocmsProduct,
}) => {
	const { locale } = useLocale();
	const { datoCmsGeneral } = useStaticQuery<StaticQuery>(staticQuery);
	const firstLoad = useFirstLoad();

	const totalSlides = (videos?.length ?? 0) + images.length;

	// State variables
	const [selectedIndex, setSelectedIndex] = useState(
		totalSlides > 0 ? 0 : undefined,
	);

	// Effects
	useEffect(() => {
		if (selectedVariant && (!videos?.length || !firstLoad)) {
			if (
				images &&
				images.length > 0 &&
				selectedVariant &&
				selectedVariant.media.length > 0
			) {
				const foundIndex = images.findIndex(
					(image) =>
						image.shopifyId ===
						getFirstMediaImage(selectedVariant.media)?.shopifyId,
				);
				if (foundIndex >= 0) {
					setSelectedIndex(foundIndex + (videos?.length ?? 0));
				}
			}
		}
	}, [selectedVariant]);

	const isDiscounted =
		selectedVariant &&
		selectedVariant.compareAtPrice &&
		selectedVariant.compareAtPrice !== selectedVariant.price;
	const discountedProductText = getLocalizedValue(
		datoCmsGeneral._allDiscountedProductTextLocales,
		locale,
	);
	const discountPercentage = isDiscounted
		? Math.round(
				((selectedVariant.price - selectedVariant.compareAtPrice!) /
					selectedVariant.compareAtPrice!) *
					100,
			)
		: null;

	return (
		<Container>
			<BackgroundBox />
			<ImageContainer>
				<LabelsContainer>
					{/* {isDiscounted && discountedProductText && (
            <DiscountLabel>{discountedProductText}</DiscountLabel>
          )} */}
					{isDiscounted && <DiscountLabel>{discountPercentage}%</DiscountLabel>}
					{datocmsProduct.featured && <FeaturedLabel>♥︎</FeaturedLabel>}
				</LabelsContainer>

				<CarouselProvider
					totalSlides={totalSlides}
					currentSlide={selectedIndex}
				>
					<Slider onAfterDrag={(n) => setSelectedIndex(n)}>
						{videos &&
							videos.map((video, index) => (
								<Slide key={index} index={index}>
									<Video
										src={video.originalSource?.url}
										autoPlay
										muted
										playsInline
										loop
									/>
								</Slide>
							))}
						{images &&
							images.map((image, index) => (
								<Slide key={index} index={index + (videos?.length ?? 0)}>
									<Image
										image={image.gatsbyImageData}
										alt={`${
											datocmsProduct.category && datocmsProduct.category.title
										} - ${datocmsProduct.title} - ${index + 1} | Rue des Mille`}
									/>
								</Slide>
							))}
					</Slider>
				</CarouselProvider>
			</ImageContainer>
			{totalSlides > 1 && (
				<ThumbnailsContainer>
					<CarouselProvider
						totalSlides={totalSlides}
						visibleSlides={4}
						currentSlide={selectedIndex}
					>
						<Slider onAfterDrag={(n) => setSelectedIndex(n)}>
							{videos &&
								videos.map((video, index) => (
									<Slide
										key={index}
										index={index}
										onClick={(n) => setSelectedIndex(n)}
									>
										<ThumbnailContainer>
											<ImageThumbnail
												image={video.preview?.image?.gatsbyImageData}
												alt={`${
													datocmsProduct.category &&
													datocmsProduct.category.title
												} - ${datocmsProduct.title} - thumbnail - video - ${
													index + 1
												} | Rue des Mille`}
											/>
											<PlayLogo />
											<ThumbnailFrame selected={selectedIndex === index} />
										</ThumbnailContainer>
									</Slide>
								))}
							{images &&
								images.map((image, index: number) => {
									const realIndex = index + (videos?.length ?? 0);
									return (
										<Slide
											key={index}
											index={realIndex}
											onClick={(n) => setSelectedIndex(n)}
										>
											<ThumbnailContainer>
												<ImageThumbnail
													image={image.gatsbyImageData}
													alt={`${
														datocmsProduct.category &&
														datocmsProduct.category.title
													} - ${datocmsProduct.title} - thumbnail - ${
														index + 1
													} | Rue des Mille`}
												/>
												<ThumbnailFrame
													selected={selectedIndex === realIndex}
												/>
											</ThumbnailContainer>
										</Slide>
									);
								})}
						</Slider>
						<ButtonBack onClick={(n: number) => setSelectedIndex(n)}>
							<CircleArrowLeft />
						</ButtonBack>
						<ButtonNext onClick={(n: number) => setSelectedIndex(n)}>
							<CircleArrowRight />
						</ButtonNext>
					</CarouselProvider>
				</ThumbnailsContainer>
			)}
		</Container>
	);
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      _allDiscountedProductTextLocales {
        locale
        value
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin-top: 40px;
  @media (max-width: 450px) {
    margin-top: 20px;
  }
`;

const BackgroundBox = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.background2};
  height: calc(100% + 80px);
  width: calc((100vw - 960px) / 2 + 640px);
  padding: 40px;
  top: -40px;
  right: -40px;
  @media (max-width: 1280px) {
    height: calc(100% + 80px);
    width: 680px;
    padding: 40px;
    top: -40px;
    right: -40px;
  }
  @media (max-width: 1100px) {
    height: calc(100% + 80px);
    width: calc(100% + 20px);
    padding: 40px;
    top: -40px;
    left: -20px;
  }
  @media (max-width: 900px) {
    height: calc(100% + 80px);
    width: 100%;
    padding: 40px;
    top: -40px;
    left: 0;
  }
  @media (max-width: 450px) {
    height: calc(100% + 40px);
    width: 100%;
    padding: 20px;
    top: -20px;
    right: 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  @media (max-width: 1280px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) {
    padding: 0 40px 0 20px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 450px) {
    padding: 0 20px;
  }
`;

const LabelsContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  display: flex;
  @media (max-width: 1100px) {
    right: 60px;
  }
  @media (max-width: 450px) {
    right: 40px;
  }
`;

const DiscountLabel = styled.div`
  padding: 0 10px 1px 10px;
  height: 26px;
  background-color: #d24017;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeaturedLabel = styled.div`
  padding-top: 1px;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.main};
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  box-shadow: 0px 0px 6px 0px rgba(151, 123, 43, 0.7);
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

const Video = styled.video`
  width: 100%;
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

const PlayLogo = styled(Play)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  fill: #000;
  opacity: 0.8;
  @media (max-width: 756px) {
    width: 20px;
  }
`;

const ThumbnailsContainer = styled.div`
  width: 100%;
  position: relative;
  width: calc(100% + 10px);
  margin-top: 10px;
  margin-left: -5px;
  @media (max-width: 1280px) {
    width: calc(100% - 20px + 10px);
    margin-left: calc(20px - 5px);
  }
  @media (max-width: 1100px) {
    width: calc(100% - 60px + 10px);
    margin-left: calc(20px - 5px);
  }
  @media (max-width: 1000px) {
    width: calc(100% - 80px + 10px);
    margin-left: calc(40px - 5px);
  }
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  &:focus {
    outline: none;
  }
`;
const ImageThumbnail = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;
const ThumbnailFrame = styled.div`
  z-index: 1;
  height: calc(100% - 8px);
  left: 0;
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% - 8px);
  pointer-events: none;
  transition: 0.3s all;
  box-shadow: inset 0px 0px 0px 4px transparent;
  margin: 4px;
  ${({ selected }: { selected: boolean }) => {
		if (selected) {
			return `box-shadow: inset 0px 0px 0px 4px #977B2B`;
		}
	}}
`;

export default Gallery;
