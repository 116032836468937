import { DatoCmsProduct } from "graphql-typings";
import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Cross from "./icons/Cross";

interface Props {
  isOpen: boolean;
  data: DatoCmsProduct["sizeGuide"];
  onClose: () => void;
}

const SizeGuideModal = ({ isOpen, data, onClose }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  return isOpen ? (
    <ModalMask onClick={handleClose}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <CloseIcon onClick={handleClose} />
        <Content>
          {/* <ModalTitle>{data!.title}</ModalTitle> */}
          <ModalText
            dangerouslySetInnerHTML={{
              __html: data!.contentNode!.childMarkdownRemark!.html || ""
            }}
          />
        </Content>
      </ModalContainer>
    </ModalMask>
  ) : null;
};

const arriveModal = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1001;
  width: 700px;
  background-color: #fff;
  color: $baseTextColor;
  box-sizing: border-box;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  animation: ${arriveModal} 400ms ease-in-out 0s forwards;
  overflow-x: hidden;
  max-height: 85%;
  top: 5%;
  transform: translate3d(0, 5%, 0);
  overflow-y: auto;
  @media (max-width: 750px) {
    width: 90%;
  }
`;

const ModalMask = styled.div`
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
`;

const CloseIcon = styled(Cross)`
  position: fixed;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
    color: #bdbdbd;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  @media (max-width: 750px) {
    padding: 30px 30px;
  }
`;

const ModalTitle = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const ModalText = styled.div`
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
  color: #333;
  text-align: center;
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.8em;
    margin: 0;
    margin-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 20px;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
  }
  h3 {
    font-size: 18px;
    letter-spacing: 0.04em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
  }
  pre {
    white-space: normal;
  }
  img {
    width: 100%;
    display: block;
    margin: 20px 0;
  }
  ul,
  ol {
    li {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.8em;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  table {
    table-layout: auto;
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    th {
      background-color: #eee;
    }
    td,
    th {
      border: 1px solid #999;
      line-height: 1.7em;
      padding: 0.5rem;
      text-align: left;
      vertical-align: top;
    }
  }
  blockquote {
    background-color: ${({ theme }) => theme.colors.background2};
    padding: 60px 40px;

    margin: 30px auto;
    box-sizing: border-box;
    position: relative;

    width: 100%;
    &:before,
    &:after {
      content: "“";
      display: block;
      position: absolute;
      color: ${({ theme }) => theme.colors.main};
      opacity: 0.3;
      font-size: 90px;
      font-weight: 400;
    }
    &:before {
      content: "“";
      top: -10px;
      left: 10px;
    }
    &:after {
      content: "”";
      bottom: -50px;
      right: 10px;
    }
    p {
      width: 100%;
      position: relative;
      color: ${({ theme }) => theme.colors.main};
      font-weight: 700;
      padding: 0;
    }
    ul {
      list-style: none;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      text-align: right;
      li {
        color: ${({ theme }) => theme.colors.main};
        font-size: 14px;
        margin-bottom: -20px;
      }
    }
  }
`;

export default SizeGuideModal;
