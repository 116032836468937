import React, { useState } from "react";
import styled from "styled-components";
import { colorMap } from "utils/common/colors";

interface Props {
  title: string;
  shopifyOptionName: string;
  value: string;
  options: any[];
  onChange: (option: any) => void;
}

/**
 * Generic selector for color-like variants
 */
const ColorSelector: React.SFC<Props> = ({
  title,
  shopifyOptionName,
  value,
  options,
  onChange
}) => {
  const [selectedColor, setSelectedColor] = useState(value);

  /**
   * Handle color selection
   */
  const handleSelection = (color: string) => {
    setSelectedColor(color);
    onChange({ [shopifyOptionName]: color });
  };

  const extractColorLocalizedName = () =>
    options.find(opt => opt.name === selectedColor).displayValue;

  return (
    <Container>
      <Title>
        {title}: {extractColorLocalizedName()}
      </Title>
      <VariantSelector>
        {options.map((color: any, index: number) => (
          <Variant key={index}>
            <VariantInput
              id={`${title}-${index}`}
              type="radio"
              name={title}
              value={color.name}
              checked={selectedColor === color.name}
              onChange={() => handleSelection(color.name)}
            />
            <VariantLabel htmlFor={`${title}-${index}`}>
              <Text>{color.displayValue}</Text>
              <Color color={color.name} />
            </VariantLabel>
          </Variant>
        ))}
      </VariantSelector>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: 14px;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
`;

const VariantSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
`;

const Variant = styled.div`
  margin: 8px 10px;
  &.hidden {
    display: none;
  }
`;

const Color = styled.div`
  ${(props: any) => {
    const color = colorMap[`${props.color.toLowerCase()}`];
    if (typeof color === "string") {
      return `background-color:${color}`;
    }
    if (Array.isArray(color)) {
      const percentage = 100 / color.length;
      let configuration = "";
      for (let i = 0; i < color.length; i++) {
        configuration += `${color[i]} ${percentage * i}%, ${
          color[i]
        } ${percentage * (i + 1)}%${i + 1 < color.length ? "," : ""}`;
      }
      return `background: linear-gradient(90deg, ${configuration});`;
    }
    return `background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
    background-image: linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      ),
      linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      );`;
  }};
  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border: 2px solid rgba(213, 213, 213, 0);
    border-radius: 100%;
    transition: 0.4s all;
  }
`;

const VariantInput = styled.input`
  display: none;

  &:checked ~ label {
    ${Color} {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
      transform: scale(1, 1);
      &::before {
        border: 2px solid rgba(213, 213, 213, 1);
      }
    }
  }
  ~ label {
    cursor: pointer;
    ${Color} {
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      transition: transform 400ms;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
      position: relative;
      &:hover {
        transform: scale(1.2, 1.2);
      }
    }
  }
`;

const VariantLabel = styled.label``;

const Text = styled.span`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
`;

export default ColorSelector;
