import React, { SVGProps } from "react";

const Play = (props: SVGProps<SVGSVGElement>) => {
  return (
    // @ts-ignore
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
    </svg>
  );
};

export default Play;
