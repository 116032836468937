import { useEffect, useRef } from "react";

export const useFirstLoad = () => {
  const firstLoad = useRef(true);

  useEffect(() => {
    firstLoad.current = false;
  }, []);

  return firstLoad.current;
};
